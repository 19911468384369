@font-face {
  font-family: myFirstFont;
  src: url("../assets/VCR_OSD_MONO_1.001.ttf");
}
@font-face {
  font-family: mySirstFont;
  src: url("../assets/PressStart.ttf");
}
:root {
  --vh: 100vh; /* Default for browsers that don't support JS */
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
}
li:active {
  box-shadow: none !important;
}
.textPath {
  color: yellow;
}
.welcome-para {
  font-size: 40px;
  padding-bottom: 10px;
}
.carousel-indicators [data-bs-target] {
  background-color: #41ff3f !important;
}
.warning {
  text-align: center;
  padding: 20px;
  display: flex;
  align-items: center;
  background-color: #000;
  color: #4df038;
  font-size: 1.2rem;
  justify-content: center;
  height: 100vh;
}

.bordersec1 {
  display: inline-block;
  /* margin: 5px; */
  /* border-radius: 3px; */
  border: 4px dashed #09bd1b;
  /* background: linear-gradient(#fff 0 0) padding-box,
    linear-gradient(to bottom, #4fc3f7, #ab5ca4 49%, #ff512f) border-box; */
}

.bordersec {
  display: inline-block;
  margin: 5px;
  /* border-radius: 3px; */
  border: 4px dashed #09bd1b;

  /* background: linear-gradient(#fff 0 0) padding-box,
    linear-gradient(to bottom, #4fc3f7, #ab5ca4 49%, #ff512f) border-box; */
}
.custom-carousel .carousel-control-prev,
.custom-carousel .carousel-control-next {
  display: none !important; /* Hides the arrows */
}
.carousel-indicators {
  margin-bottom: -2rem !important;
}
.bordersec h3 {
  padding: 35px 0px;
  text-transform: uppercase;
  font-family: mySirstFont;
  border-bottom: 3px DASHED #c75cd8;
  color: rgba(250, 230, 29, 1);
}
.bordersec1 h3 {
  padding: 35px 0px;
  font-family: mySirstFont;
  border-bottom: 3px DASHED #c75cd8;
  background: linear-gradient(
    264deg,
    #fd8d23 34.66%,
    #fae61c 49.31%,
    #eef73b 60.95%,
    #4cf038 77.23%,
    #33c6f6 84.04%,
    #559cfb 94.14%,
    #7475ff 75.74%,
    #d457ff 100%
  );

  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 2.5rem !important;
}
.bordersec1 h6 {
  color: rgba(250, 230, 29, 1);
  text-transform: uppercase;
  font-size: 1.2rem;
}

.bordersec1 p {
  /* padding: 25px 10px; */
  font-size: 1rem;
  text-align: center;
  color: #747474;
  text-transform: capitalize;
}

.bordersec p {
  padding: 35px 10px;
  font-size: 20px;
  margin-bottom: 0px;
  text-align: center;
  background: linear-gradient(
    180deg,
    #fd8d23 6.66%,
    #fae61c 20.31%,
    #eef73b 38.95%,
    #4cf038 60.23%,
    #33c6f6 65.04%,
    #559cfb 84.14%,
    #7475ff 90.74%,
    #d457ff 100%
  );

  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.videoInsert {
  position: absolute;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -100;
  background-size: cover;
  overflow: hidden;
}

.memetv {
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
}

.animation-logo {
  animation: zoom-in 1s forwards;
  scale: 1.5;
}
@keyframes zoom-in {
  0% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1.2); /* Adjust the scale factor for desired zoom level */
  }
}
.btn-outline {
  border: 2px solid;
  padding: 9px 33px;
  color: #0abd1c;
}
.welcome-info {
  width: 590px;
  background: #000000e0;
  padding: 25px;

  border: 2px solid #0abd1c;
}
.about-gif-part {
  text-align: -webkit-center;
  margin-top: 15px;
}
.about-gif-part img {
  margin-top: 30px;
  width: 100px;
}
.playvideo {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}
.hidevideo {
  width: 0;
  height: 0;
  object-fit: cover;
}

.welcome-info1 {
  width: 590px;
  padding: 25px;
}
/* .playvideo {
  width: 100%;
  height: auto;
  display: block;
}
.hidevideo {
  display: none;
} */

.cnt-outline {
  border: 1px solid #000;
  color: #2a585b;
  border-radius: 1px;
  padding: 1px;
}
.cnt-wallet {
  background-color: #0e4911;
  color: hsl(120, 5%, 15%);
  border-radius: 1px;
  padding: 1px;
  border: 1ps solid #0e4911;
}
.intro-wallet {
  display: flex;
  position: absolute;
  z-index: 99999999;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 70vh; /* Full height of the viewport */
  width: 100%;
}
.cnt-outline {
  border: 1px solid #000;
  color: #2a585b;
  border-radius: 1px;
  padding: 1px;
}
.cnt-wallet {
  background-color: rgb(10 189 28);
  color: #2a585b;
  border-radius: 1px;
  padding: 1px;
}
/* On screens that are 992px or less, set the background color to blue */
.do1 {
  color: #fae61d !important;
}
.do2 {
  color: #4aff65 !important;
}
.do3 {
  color: #ee2e95 !important;
}
/*video
  {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
*/
.fnt-back {
  font-size: 16px !important;
  text-decoration: underline !important;
  position: absolute;
  margin-top: -10px;
  right: 80px;
}
.fnt-back img {
  width: 70px !important;
}
.text-right {
  text-align: right;
  text-align: -webkit-right;
}
.text-c {
  text-align: -webkit-center !important;
}
.handgif img {
  width: 40px;
}

.card {
  background-color: rgba(0, 0, 0, 0.3);
  /* backdrop-filter: blur(10%); */
  height: 100%;
  width: 100%;
}

.cardd {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /* height: 215px; */
  display: flex;
}

.text-head2 {
  background: var(
    --Gradent,
    linear-gradient(
      240deg,
      #ff7c00 13.66%,
      #fae61c 21.31%,
      #eef73b 20.95%,
      #4cf038 56.23%,
      #33c6f6 90.04%,
      #559cfb 65.14%,
      #7475ff 88.74%,
      #d457ff 100%
    )
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.btn-line {
  border-bottom: 2px solid;
  padding: 4px;
  font-size: 20px;
}

/*video
{
  width: 100%;
    height: 100%;
    object-fit: cover;
} 
*/
@media screen and (min-width: 993px) and (max-width: 1550px) {
  .text-1 {
    color: #43b736;
  }
  .gif-size {
    width: 85%;
    margin-top: 10%;
  }
  .f18 {
    font-size: 25px !important;
    /* padding-top: 5px; */
    /* padding-bottom: 5px; */
    background: var(
      --Gradent,
      linear-gradient(
        180deg,
        #fd8d23 17.66%,
        #fae61c 18.31%,
        #eef73b 29.95%,
        #4cf038 42.23%,
        #33c6f6 65.04%,
        #559cfb 84.14%,
        #7475ff 66.74%,
        #d457ff 100%
      )
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .text-ab {
    color: #fff;
  }
  .bg-span {
    margin-left: 0px;
    background-color: #58c340;
    color: #ffffff !important;
    padding: 2px;
  }
  .slider-track {
    display: flex;
    gap: 10px;
    transition: transform 0.5s ease-in-out;
  }
  .slider-arrow {
    background: none;
    border: none;
    cursor: pointer;
    position: relative;
    /* top: 50%; */
    /* transform: translateY(-50%); */
    /* z-index: 1000; */
    margin-top: 25px;
  }
  .left-arrow {
    margin-right: 10px;
  }
  .right-arrow {
    margin-left: 10px;
  }
  .img-desk {
    width: 100%;
    height: auto;
  }
  body {
    margin: 0;
    padding: 0;
    height: 100vh;
    /* display: flex; */
    justify-content: center;
    align-items: center;
  }

  .road1 {
    align-items: center;
  }
  .road-2 {
    margin-top: -150px !important;
  }
  .donothing {
    color: #1fdf08 !important;
    font-size: 18px;
    /* text-transform: uppercase; */
  }
  .desk-wel {
    display: none !important;
  }
  .claim-1 {
    margin-left: 15px !important;
  }
  .starwars {
    margin: 0;
    /* background-color: #000; */
    overflow: hidden !important;
  }
  .starwars::after {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 80vh;
    /* background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 1) 61%,
      rgba(0, 0, 0, 0.54) 79%,
      rgba(0, 0, 0, 0) 100%
    ); */
    z-index: 99999;
  }
  .stars {
    position: absolute;
    width: 1px;
    height: 1px;
    background-color: #fff;
    z-index: 2;
  }
  .intro {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    font-weight: 500;
    font-size: 25px;
    padding: 30px;
    width: 80%;
    opacity: 0;
    animation: intro 2s ease-out 0.5s;
  }
  @keyframes intro {
    0% {
      opacity: 0;
    }
    20% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  #scroller {
    font-weight: bold;
    font-size: 356%;
    text-align: justify;
    overflow: hidden;
    height: 100em;
    width: 100em;
    position: absolute;
    bottom: 0;
    /* left: 40%; */
    /* margin-left: -10em; */
    z-index: 0;
    transform: perspective(260px) rotateX(19deg);
    transform-origin: 50% 100%;
  }
  #scroller::after {
    position: absolute;
    content: "";
    bottom: 60%;
    left: 0;
    right: 0;
    top: 0;
  }
  #content {
    position: absolute;
    top: -100%;
    animation: scoller 250s linear infinite;
  }
  #content p {
    font-size: 40px;
    text-align: center;
  }
  @keyframes scoller {
    0% {
      top: 100%;
    }
    100% {
      top: -100%;
    }
  }

  body {
    background: #453522 !important;
    font-family: myFirstFont !important;
  }

  html,
  body {
    margin: 0;
    padding: 0;
    overflow-x: hidden; /* Prevent horizontal scroll */
  }
  .about-para1 {
    font-size: 50px !important;
    padding-bottom: 12px;
  }
  .about-pad p {
    font-size: 20px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .claim-h3 {
    line-height: 35px;
    padding: 1px !important;
  }
  .center-content {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height: 55vh;
    /* height: 70%; */
  }
  .mob-desk {
    display: none !important;
  }
  h3 {
    font-size: 23px !important;
  }
  .meme-img img {
    background-position: center;
    width: 100%;
    /* height: 150px; */
  }
  .inner-text {
    text-align: center;
    font-size: 17px;
    color: #29585bff;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* overflow: scroll !important; */
    /*padding:15px;*/
  }
  .inner-text1 {
    text-align: center;
    font-size: 17px;
    color: #29585bff;
    width: 100%;
    height: 100%;
    /* overflow: scroll !important; */
    /*padding:15px;*/
  }
  .navbar-1 {
    display: none !important;
  }
  .img-res {
    width: 100%;
    height: 100%;
    transform: translate(0%, 35%);
  }
  .ul-button {
    /* font-weight: bolder; */
    border: 2px solid #6b6b6b1c;
    padding-left: 0px;
  }

  .ul-button li {
    /* border: 1px solid #6b6b6b75; */
    padding: 10px;
    cursor: pointer;
    list-style-type: none;
    /* background: var(--Gradent, linear-gradient(180deg, #FD8D23 -1.34%, #FAE61C 10.31%, #EEF73B 20.95%, #4CF038 42.23%, #33C6F6 65.04%, #559CFB 73.14%, #7475FF 80.74%, #D457FF 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  }
  .ul-button li img {
    position: absolute;
    display: flex;
  }

  span {
    margin-left: 30px;
    color: aliceblue;
    text-transform: uppercase;
  }
  .hippe {
    margin-left: 0px !important;
  }
  h2 {
    font-family: myFirstFont !important;
    z-index: 1 !important;
  }
  .header-line {
    font-size: 40px;
    background: var(
      --Gradent,
      linear-gradient(
        to bottom,
        #fd8d23 -1.34%,
        #fae61c 10.31%,
        #eef73b 20.95%,
        #4cf038 42.23%,
        #33c6f6 65.04%,
        #559cfb 73.14%,
        #7475ff 80.74%,
        #d457ff 100%
      )
    );
    -webkit-background-clip: text; /* Apply gradient to text */
    -webkit-text-fill-color: transparent; /* Hide original text */
  }

  .shadow {
    position: absolute;
    top: 15px;
    left: 50px;
    z-index: -1;
    font-size: 48px;
    font-weight: bold;
    color: rgba(255, 0, 255, 0.5);
    font-family: myFirstFont !important;
    text-shadow: 0px 4px 4px #f82f9b;
  }

  #tv {
    background: radial-gradient(#060606, #313131, #101010);
    border-radius: 0px;
    /* box-shadow: inset 0 5px 5px #121212, inset 5px 0 5px #555, inset -5px 0 5px #555, inset 0 -5px 5px #555; */
    display: flex;
    /* flex-direction: row; */
    justify-content: space-between;
    align-content: right;
    align-items: center;
    margin: 0px auto;
    position: relative;
    padding: 10px;
    width: 100%;
    z-index: 0;
    height: 94vh;
    border: 20px solid transparent;
    border-image: url("../assets/Image-Recovered.png") 30 round;
  }

  .inner-glass {
    /* background: #1a1a1a linear-gradient(transparent, #333); */
    /*border-top: 1px solid #433;*/
    /* border-bottom: 1px solid #433; */
    /*border-radius: 50% / 10%;
      box-shadow: inset 0 1px 6px #121212, inset 6px 0 5px #555, inset -6px 0 7px #555, inset 0 6px 3px #555; */
    margin: 2% auto;
    overflow: hidden;
    pointer-events: all;
    position: relative;
    width: 95%;
    height: 95%;
    /* box-shadow: 0 2px 2px #888, 2px 0 2px #444, -2px 0 2px #444, 0 -2px 2px #111;*/
  }

  #screen {
    /* border: 2px solid #969696ff;*/
    border-radius: 0px;
    /*box-shadow: 0 2px 2px #888, 2px 0 2px #444, -2px 0 2px #444, 0 -2px 2px #111; */
    background: linear-gradient(
      86deg,
      rgb(47 46 46) 1%,
      rgb(131 132 135 / 29%) 10%,
      rgb(0 0 0) 50%,
      rgb(133 133 133 / 25%) 90%,
      rgb(0 0 0) 100%
    );
    /*box-shadow: inset 0 4px 5px #444, inset 4px 0 5px #666, inset -2px 0 5px #888, inset 0 0px 5px #888; */
    width: 100%;
    height: 100%;
    background-image: url("../assets/glass-inside.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    pointer-events: none;
  }

  #glass {
    background: #000;
    /*border-top: 1px solid #433;*/
    /*border: 5px solid #000;*/
    /* border-bottom: 1px solid #433; */
    /* border-radius: 50% / 10%;*/
    /*box-shadow: inset 0 2px 4px #000, inset 0 3px 4px #000, inset -7px 0 -2px #000, inset -8px 0 6px #000;*/
    margin: 2% auto;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 95%;
    z-index: -1;
  }

  #controls {
    border-radius: 10px;
    width: 18%;
    height: 100%;
    margin-left: 20px;
  }

  #panel {
    background: #b1b1b1 linear-gradient(90deg, transparent, #888);
    border-radius: 10px;
    box-shadow: inset 0 3px 4px #000;
    margin: 2% auto;
    padding: 5px;
    border: 2px solid #232323;
    width: 100%;
    background: #1c1c1c;
    /* height: 35%; */
  }
  .nav-1 {
    padding: 5px;
    margin-top: 3%;
    margin-left: 7%;
    width: 100%;
    position: absolute;
  }
  .nav-2 {
    padding: 5px;
    margin-top: 5%;
    margin-left: 6%;
    width: 127px;
    position: relative;
  }

  .handle {
    background: #444;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 5px #000;
    position: absolute;
    top: 50px;
    left: 28px;
    width: 50px;
    height: 8px;
  }
  .do-nothing {
    /* box-shadow: inset 0 3px 4px #000; */
    margin-bottom: 5px;
    /* background: black; */
    border-radius: 5px;
    border: 2px solid #434343;
    /* background-color: #e5e5f7; */
    background-color: rgb(1, 39, 3, 1);
    opacity: 1;
    background-image: linear-gradient(0deg, #042d01 70%, #0a0a0a 50%);
    background-size: 18px 3px;
    padding: 7px;
    width: 100%;
    font-size: 25px;
    line-height: 30px;
    text-align: center;
  }
  #navi-video {
    /* box-shadow: inset 0 3px 4px #000; */
    margin-bottom: 5px;
    /* background: black; */
    border-radius: 5px;
    border: 2px solid #434343;
    /* background-color: #e5e5f7; */
    background-color: rgb(1, 39, 3, 1);
    opacity: 1;
    background-image: linear-gradient(0deg, #042d01 70%, #0a0a0a 50%);
    background-size: 18px 3px;
    padding: 7px;
    width: 100%;
    font-size: 26px;
    text-align: center;
  }
  .btn-color {
    background: none;
    border: none;
  }
  .btn-color h3 {
    color: #09bd1b;
    /* margin-top: 7px;
  */
  }
  .vertical-lines {
    display: flex;
  }

  .control-1 {
    margin-top: 5px;
    /*box-shadow: inset 0 3px 4px #000;*/
    /* border-radius: 5px;
    border: 2px solid #232323; */
    background: #1c1c1c;
    padding: 5px;
  }
  .line {
    border-left: 3px solid #3e3e3e;
    height: 220px;
    overflow: hidden;
    margin-left: 6px;
  }

  .line:first-child {
    border-left: none;
  }

  #speaker {
    background-image: url("../assets/wallet-p.svg");
    align-items: center;
    /* background-position: 50%; */
    background-repeat: no-repeat;
    background-size: cover;
    /* background-size: 100% 100%; */
    display: flex;
    height: 250px;
    justify-content: center;
  }

  .hole {
    background: #000;
    border-left: 6px solid green;
  }

  #legs {
    background: linear-gradient(
      90deg,
      transparent 10%,
      #210 10%,
      #210 25%,
      transparent 25%,
      transparent 75%,
      #210 75%,
      #210 90%,
      transparent 90%,
      transparent
    );
    position: absolute;
    left: 0;
    bottom: -44px;
    width: 100%;
    height: 40px;
  }

  /* ##Functions# */

  .text-head1 {
    background: var(
      --Gradent,
      linear-gradient(
        180deg,
        #fd8d23 -1.34%,
        #fae61c 10.31%,
        #eef73b 20.95%,
        #4cf038 42.23%,
        #33c6f6 65.04%,
        #559cfb 73.14%,
        #7475ff 80.74%,
        #d457ff 100%
      )
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    /*box-shadow: 0 5px #141414;*/
    transform: translateY(4px);
  }

  /* ##Functions# */
  .text-head {
    background: var(
      --Gradent,
      linear-gradient(
        180deg,
        #fd8d23 -1.34%,
        #fae61c 10.31%,
        #eef73b 20.95%,
        #4cf038 42.23%,
        #33c6f6 65.04%,
        #559cfb 73.14%,
        #7475ff 80.74%,
        #d457ff 100%
      )
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .trasition-1 {
    /* transform: translate(0%, 10%); */
    text-align: -webkit-center;
    text-align: -moz-center;
    padding: 0px 100px 0px 100px;
  }
  .trasition-3 {
    /* transform: translate(0%, 2%); */
    text-align: -webkit-center;
    padding: 0px 50px 0px 50px;
  }
  .trasition-4 {
    /* transform: translate(0%, 10%); */
    text-align: -webkit-center;
    /* padding: 0px 50px 0px 50px; */
  }
  .claim-reward {
    font-size: 20px;
  }
  #textcolorabout {
    color: #ffffff;
    font-family: mySirstFont !important;
    /* margin-left: 150px;  */
    margin-top: 10px;
    filter: drop-shadow(2px 2px 0px rgba(248, 47, 155, 1));
    text-transform: uppercase;
    font-size: 35px !important;
  }

  #textcolortoken {
    color: #fff;
    /* margin-left: 150px;*/
    /* margin-top: 50px; */
    filter: drop-shadow(2px 2px 0px rgba(248, 47, 155, 1));
    text-transform: uppercase;
    font-family: mySirstFont !important;
  }

  #textcolorsocial {
    color: red;
    /*margin-left: 150px;*/
    /* margin-top: 50px; */
  }
  #textcolorroadmap span {
    font-size: 15px !important;
  }
  #textcolorroadmap {
    color: #fff;
    /* margin-left: 150px; */
    margin-bottom: 10px;
    font-size: 45px;
    text-transform: uppercase;
    font-family: mySirstFont !important;
    text-align: center;
    filter: drop-shadow(2px 2px 0px rgba(248, 47, 155, 1));
  }

  #textcolorsocial {
    color: #fff;
    font-family: mySirstFont !important;
    font-size: 40px;
    margin-bottom: 15px;
    /* margin-top: 20px; */
  }

  #playbutton {
    color: rgb(7, 6, 6);
    font-weight: bolder;
  }

  #imagetext {
    font-size: larger;
    color: #4cf038;
    /*margin-left: 34px;*/
  }
  /* Create three equal columns that floats next to each other */
  .column {
    float: left;
    width: 33.33%;
    padding: 10px;
    /* height: 300px; /* Should be removed. Only for demonstration */
  }

  /* Clear floats after the columns */
  .row:after {
    content: "";
    display: table;
    clear: both;
  }
  .row {
    /*padding: 20px;*/
    text-align: -webkit-center;
  }
  .road-map {
    padding-top: 25px;
    font-size: 20px !important;
    padding-bottom: 25px;
    margin-top: -36px;
    /* margin-left: 15px;
        margin-right: 15px; */
    background: black;
    /* margin-right: 15px; */
    border: 2px solid #63ef39;
    width: 85%;
    box-shadow: 2px 6px 8px #f82f9b;
    border: 2px solid transparent;
    border-image-slice: 1;
    border-image-source: linear-gradient(
      180deg,
      #fd8d23 -1.34%,
      #fae61c 10.31%,
      #eef73b 20.95%,
      #4cf038 42.23%,
      #33c6f6 65.04%,
      #559cfb 73.14%,
      #7475ff 80.74%,
      #d457ff 100%
    );
  }
  .road-map-txt {
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 18px;
    padding: 10px;
  }
  .road-map-dash {
    border: 2px solid transparent;
    border-image-source: linear-gradient(
      180deg,
      #fd8d23 -1.34%,
      #fae61c 10.31%,
      #eef73b 20.95%,
      #4cf038 42.23%,
      #33c6f6 65.04%,
      #559cfb 73.14%,
      #7475ff 80.74%,
      #d457ff 100%
    );
    border-image-slice: 1;
    padding: 10px;
    height: 100%;
    width: 85%;
  }

  .roadmapimg {
    padding-bottom: 10px;
  }

  .social-1 {
    text-align: right;
    position: absolute;
    display: flex;
    margin-top: -20px;
    right: 30px;
  }

  /* Glitch */
  /*
    #glitch-background {
      height: 850px;
      width: 850px;
      /* position: fixed; 
      top: 0;
      left: 0;
      background-color: #0d0c0c;
      background-image: radial-gradient(circle, #5d5d5d 1px, black 1px);
      background-size: 10px 10px;
      animation: glitch 1s infinite linear;
      overflow: hidden;
    } 
  
  @keyframes glitch {
    0%, 100% {
      background-position: 0 0, 0 0, 0 0;
    }
    10% {
      background-position: -5px -5px, 5px 5px, -5px 5px;
    }
    20% {
      background-position: 5px -5px, -5px 5px, 5px 5px;
    }
    30% {
      background-position: -5px 5px, 5px -5px, -5px -5px;
    }
    40% {
      background-position: 5px 5px, -5px -5px, 5px -5px;
    }
    50% {
      background-position: 0 0, 0 0, 0 0;
    }
    60% {
      background-position: -5px -5px, 5px 5px, 5px -5px;
    }
    70% {
      background-position: 5px 5px, -5px 5px, -5px -5px;
    }
    80% {
      background-position: -5px 5px, 5px -5px, 5px 5px;
    }
    90% {
      background-position: 5px -5px, -5px -5px, -5px 5px;
    }
  }  */

  .noise {
    width: 100%;
    height: 100%;
    opacity: 0.7;
    background: linear-gradient(
        to bottom,
        transparent,
        #aaa4,
        #8881,
        #6664,
        #4445,
        #2228,
        #4443,
        transparent
      ),
      repeating-linear-gradient(transparent 0 2px, #25242950 2px 4px);
    -webkit-animation: moveBand 8s linear infinite;
    animation: moveBand 8s linear infinite;
    z-index: 1;
    pointer-events: none;
    position: absolute;
  }

  @-webkit-keyframes moveBand {
    0% {
      background-position-y: 0, 0;
    }
    100% {
      background-position-y: -221px, -150px;
    }
  }

  @keyframes moveBand {
    0% {
      background-position-y: 0, 0;
    }
    100% {
      background-position-y: -221px, -150px;
    }
  }
  .img-res {
    text-align: center;
    width: 100%;
    height: 100%;
    text-align: -webkit-center;
    text-align: -moz-center;
  }
  .about-para {
    margin-bottom: 5px;
    margin-top: 10px;
  }
  .road-map-text {
    /*transform: translate(0%, 25%);*/
    padding-left: 100px;
    padding-right: 100px;
  }

  .btn-primary {
    background-color: #09bd1b;
    padding: 5px 50px 5px 50px;
  }
  .do-nothing-button {
    display: flex !important;
  }

  .btn-do {
    transform: translate(23%, 0%);
    margin-top: 5px;
  }
  .supply-p {
    font-size: 50px;
    /*border: 1px solid green;*/
    padding: 10px;
  }
  .img-social {
    display: flex;
    justify-content: center; /* Centers child elements horizontally */
    align-items: center; /* Centers child elements vertically */
    padding: 20px;
  }

  .img-social ul {
    display: flex;
    text-align: -webkit-center;
    align-items: center;
    list-style-type: none;
  }

  .img-social ul li img {
    width: 40px;
    margin-right: 10px;
    cursor: pointer;
  }

  .trasition-1 h3 {
    color: #ffffff;
    margin-bottom: 25px;
  }
  .h-size {
    font-size: 70px;
  }
  .social-tag p {
    font-size: 18px;
  }
  .social-tag {
    font-size: 16px;
    padding: 15px;
    background: var(
      --Gradent,
      linear-gradient(
        180deg,
        #ffdb2e -1.34%,
        #b5ab43 10.31%,
        #eef73b 20.95%,
        #fff700 42.23%,
        #d3d062 65.04%,
        #ffb000 73.14%,
        #ff0000 80.74%,
        #ff0000 100%
      )
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  ol,
  ul,
  dl {
    margin-bottom: 0px !important;
  }
  ol,
  ul {
    padding-left: 0rem !important;
  }
  .navi {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    /*border: 2px solid #0e7616;*/
    padding: 10px;
    gap: 10;
    justify-content: center;
  }
  .navi h3 {
    font-size: 16px !important;
    padding: 5px;
    margin-top: 10px;
  }

  .tv {
    /*background: #210; */
    /* border-radius: 0px; */
    /* box-shadow: inset 0 5px 5px #121212, inset 5px 0 5px #555, inset -5px 0 5px #555, inset 0 -5px 5px #555; */
    /* position: absolute; */
    margin: 0px 0px auto;
    /* margin-top: -113px; */
    z-index: 1;
    width: 100%;
    padding: 7px;
  }
  .social-links {
    text-align: -webkit-right !important;
  }
  .social-links ul {
    /*display: flex;*/
    color: azure;
    /* width: 15%;
  */
  }
  .social-links ul li {
    margin-right: 0px;
    float: right;
    list-style-type: none;
  }
  .social-links ul li img {
    width: 30px;
    cursor: pointer;
  }
  .img-z {
    z-index: 9;
  }

  .about-gif-part {
    text-align: -webkit-center;
    margin-top: 15px;
  }
  .about-gif-part img {
    margin-top: 0px;
    width: 100px;
  }

  .about-trasition-1 {
    /* transform: translate(0%, 10%); */
    padding: 7px 100px 0px 100px;
    margin-bottom: 50%;
  }
  .token-read1 {
    /* transform: translate(0%, 20%); */
    padding: 0px 50px 0px 50px;
  }
  .token-read1 p {
    font-size: 14px;
  }

  .trasition-2 {
    /* transform: translate(0%, 10%); */
    padding: 0px 50px 0px 50px;
  }
  .wel-trasition-1 {
    /* transform: translate(0%, -35%); */
    padding: 0px 50px 0px 50px;
  }

  /* .trasition-2 img {
    width: 80px;
  } */

  /* .supply-part {
    align-items: baseline;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    padding: 0 0px;
  } */
  .supply-ocb {
    text-align: -webkit-center;
    margin-top: 10px;
  }

  .supply-token {
    padding: 20px;
  }
  .supply-token p {
    margin-bottom: 1px;
    margin-left: 1px;
    text-align: left;
    font-size: 18px;
  }
  .trasition-1 img {
    width: 80px;
    margin-bottom: 5px;
  }

  .rope-img {
    transform: translateX(0%);
    bottom: 0%;
    width: 100%;
    left: 0;
    position: absolute;
    display: flex;
    padding: 10px 0px;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  /* .rope-img img {
    width: 280px;
  } */
  .icon-1 {
    text-align: -webkit-right;
    position: absolute;
    right: 10px;
    width: 100%;
    margin-top: -20px !important;
  }
}
/* .mar-top
{
  margin-top: -10px;
} */

/* On screens that are 992px or less, set the background color to blue */
@media screen and (max-width: 992px) {
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  .navbar-toggler {
    border: none !important;
  }
  .gif-size {
    display: none !important;
  }
  .bordersec p {
    /* padding: 25px 10px; */
    font-size: 1rem;
    text-align: center;
    color: #747474;
    text-transform: capitalize;
    height: 16vh;
    display: flex;
    align-items: center;
  }
  .memetv {
    overflow: hidden !important; /* Disable horizontal scrolling */
    width: 100vw; /* Ensure it stays within the viewport width */
    box-sizing: border-box; /* Include padding and borders within the element's width */
    height: calc(var(--vh, 1vh) * 100);
  }
  .body1 {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .f18 {
    font-size: 22px !important;
    padding-top: 5px;
    padding-bottom: 5px;
    background: var(
      --Gradent,
      linear-gradient(
        180deg,
        #fd8d23 17.66%,
        #fae61c 18.31%,
        #eef73b 29.95%,
        #4cf038 42.23%,
        #33c6f6 65.04%,
        #559cfb 84.14%,
        #7475ff 66.74%,
        #d457ff 100%
      )
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  @media screen and (orientation: landscape) {
    .landscape-blocker {
      display: block;
      text-align: center;
      padding: 20px;
    }
  }
  .text-1 {
    color: #43b736;
  }
  .text-ab {
    color: #fff;
    padding-top: 10px;
    font-size: 15px;
  }
  .bg-span {
    margin-left: 0px;
    background-color: #58c340;
    color: #ffffff !important;
    padding: 2px;
  }

  body {
    margin-bottom: calc(
      -webkit-env(safe-area-inset-bottom) + constant(safe-area-inset-bottom)
    );
  }
  .w100 {
    width: 90%;
  }
  .donothing {
    color: #1fdf08 !important;
    /* text-transform: uppercase; */
    font-size: 21px;
  }
  .justify-content {
    justify-content: center;
  }
  .desk {
    display: none !important;
  }
  .slider {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
  }
  .navbar-light {
    float: inline-end;
  }
  #scroller {
    font-weight: bold;
    font-size: 350%;
    text-align: justify;
    overflow: hidden;
    height: 50em;
    width: 40em;
    position: absolute;
    bottom: 0;
    z-index: 0;
    transform: perspective(350px) rotateX(25deg);
    transform-origin: 50% 100%;
  }
  .social-mob {
    display: none !important;
  }
  .btn-line {
    border-bottom: 2px solid;
    padding: 4px;
    font-size: 15px;
  }
  .mob-wel {
    display: none !important;
  }
  .intro-wallet {
    display: flex;
    position: absolute;
    z-index: 99999999;
    justify-content: center;
    align-items: center;
    height: 68vh !important;
    width: 100%;
  }

  .starwars {
    margin: 0;
    /* background-color: #000; */
    overflow: hidden !important;
  }
  .starwars::after {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 80vh;
    /* background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 1) 61%,
      rgba(0, 0, 0, 0.54) 79%,
      rgba(0, 0, 0, 0) 100%
    ); */
    z-index: 99999;
  }
  .stars {
    position: absolute;
    width: 1px;
    height: 1px;
    background-color: #fff;
    z-index: 2;
  }
  .intro {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    font-weight: 500;
    font-size: 25px;
    padding: 30px;
    width: 80%;
    opacity: 0;
    animation: intro 2s ease-out 0.5s;
  }
  @keyframes intro {
    0% {
      opacity: 0;
    }
    20% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  #scroller::after {
    position: absolute;
    content: "";
    bottom: 60%;
    left: 0;
    right: 0;
    top: 0;
  }
  #content {
    position: absolute;
    top: -100%;
    animation: scoller 50s linear infinite;
  }
  #content p {
    font-size: 35px;
    text-align: center;
  }
  @keyframes scoller {
    0% {
      top: 100%;
    }
    100% {
      top: -100%;
    }
  }

  .rope-img {
    /* transform: translateX(100%); */
    /* bottom: -30%; */
    /* width: 100%; */
    left: 0;
    position: fixed;
  }
  .small-d {
    /* padding-top: 5px; */
    /* padding-bottom: 5px; */
    /* padding-left: 20px; */
    height: 10vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }
  .meme-img,
  #panel,
  #navi-video {
    display: none !important;
  }
  .about-para1 {
    font-size: 17px;
    padding-bottom: 0px;
  }
  .handgif {
    display: none;
  }
  .cnt-gif {
    display: none;
  }
  ol,
  ul,
  dl {
    margin-top: 0;
    margin-bottom: 0rem !important;
  }
  iframe {
    width: 100% !important;
  }
  .wel-trasition-1 {
    transform: translate(0%, -50%);
    padding: 50px;
    margin-left: 20px;
    margin-right: 10px;
  }
  #textcolorroadmap span {
    display: none;
  }
  .claim-h3 {
    font-size: 16px !important;
    padding-top: 8px;
  }
  .img-icon {
    display: none !important;
  }
  #tv {
    background: radial-gradient(#060606, #313131, #101010);
    border-radius: 0px;
    /* box-shadow: inset 0 5px 5px #121212, inset 5px 0 5px #555, inset -5px 0 5px #555, inset 0 -5px 5px #555; */
    /* display: flex; */
    position: relative;
    /* padding: 10px; */
    width: 100%;
    height: 90% !important;
    z-index: 0;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
  }

  .img-res {
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    /* transform: translate(0%, 0%); */
  }
  .wallet-desk {
    display: none !important;
  }

  .navbar-light .navbar-toggler-icon {
    background-image: url("../assets/Burger.png");
    margin-right: 20px;
    width: 25px;
  }

  .navi {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    /* border: 2px solid #0e7616; */
    /*padding: 12px; */
    gap: 10;
    justify-content: center;
  }

  #screen {
    /* border: 2px solid #969696ff;*/
    border-radius: 0px;

    /*box-shadow: 0 2px 2px #888, 2px 0 2px #444, -2px 0 2px #444, 0 -2px 2px #111; */
    background: linear-gradient(
      86deg,
      rgb(47 46 46) 1%,
      rgb(131 132 135 / 29%) 10%,
      rgb(0 0 0) 50%,
      rgb(133 133 133 / 25%) 90%,
      rgb(0 0 0) 100%
    );
    /*box-shadow: inset 0 4px 5px #444, inset 4px 0 5px #666, inset -2px 0 5px #888, inset 0 0px 5px #888; */
    width: 100%;
    height: 80% !important;
    background-image: url("../assets/Frame 21.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    pointer-events: none;
  }

  body {
    background: #222222 !important;
    font-family: myFirstFont !important;
    overflow: hidden !important;
    overflow-y: auto !important;
    height: 100%;
  }

  h3 {
    font-size: 18px !important;
    padding: 10px;
    margin-bottom: 0px !important;
  }
  .meme-img img {
    background-position: center;
    width: 100%;
  }
  .inner-text {
    text-align: center;
    font-size: 18px;
    color: #29585bff;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    pointer-events: all;
    overflow-y: hidden !important;
    overflow-x: hidden;
    /*padding:15px;*/
  }
  .inner-text1 {
    text-align: center;
    font-size: 20px;
    color: #29585bff;
    width: 100%;
    height: 100%;
    pointer-events: all;
    overflow-y: hidden;
    overflow-x: hidden;
    /*padding:15px;*/
  }
  p {
    padding-bottom: 0px !important;
    margin-bottom: 0px !important;
  }
  .ul-button {
    /* font-weight: bolder; */
    /* border: 2px solid #6b6b6b1c; */
    padding-left: 0px;
  }

  .ul-button li {
    /*border: 1px solid #6b6b6b75;*/
    padding: 10px;
    cursor: pointer;
    /* background: var(--Gradent, linear-gradient(180deg, #FD8D23 -1.34%, #FAE61C 10.31%, #EEF73B 20.95%, #4CF038 42.23%, #33C6F6 65.04%, #559CFB 73.14%, #7475FF 80.74%, #D457FF 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  }
  .ul-button li img {
    position: absolute;
    display: flex;
  }

  span {
    margin-left: 30px;
    color: aliceblue;
    text-transform: uppercase;
  }
  .hippe {
    margin-left: 0px !important;
    font-size: 18px;
  }
  h2 {
    font-family: myFirstFont !important;
    z-index: 1 !important;
  }
  .header-line {
    font-size: 48px;
    background: var(
      --Gradent,
      linear-gradient(
        55deg,
        #fd8d23 -1.34%,
        #fae61c 10.31%,
        #eef73b 20.95%,
        #4cf038 42.23%,
        #33c6f6 65.04%,
        #559cfb 73.14%,
        #7475ff 80.74%,
        #d457ff 100%
      )
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .shadow {
    position: absolute;
    top: 15px;
    left: 50px;
    z-index: -1;
    font-size: 48px;
    font-weight: bold;
    color: rgba(255, 0, 255, 0.5);
    font-family: myFirstFont !important;
    text-shadow: 0px 4px 4px #f82f9b;
  }

  .inner-glass {
    /* background: #1a1a1a linear-gradient(transparent, #333); */
    /*border-top: 1px solid #433;*/
    /* border-bottom: 1px solid #433; */
    /*border-radius: 50% / 10%;
      box-shadow: inset 0 1px 6px #121212, inset 6px 0 5px #555, inset -6px 0 7px #555, inset 0 6px 3px #555; */
    margin: 2% auto;
    overflow: hidden;
    position: relative;
    width: 95%;
    height: 95%;
    pointer-events: none;
    /* box-shadow: 0 2px 2px #888, 2px 0 2px #444, -2px 0 2px #444, 0 -2px 2px #111;*/
  }

  #glass {
    background: #000;
    /* border-top: 1px solid #433; */
    /* border: 5px solid #000; */
    /* border-bottom: 1px solid #433; */
    /* border-radius: 50% / 10%; */
    /* margin: 2% auto; */
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: -1;
  }

  #controls {
    /*background:#212121;*/
    border-radius: 10px;
    /*width: 20%;*/
    height: 15vh;
    /*margin-left: 10px;*/
    /* position: fixed; */
    width: 100%;
    /* bottom: 0px; */
  }
  .header-line {
    font-size: 22px !important;
    text-align: center;
    font-family: mySirstFont !important;
    /* margin-bottom: 40px; */
  }
  #panel {
    background: #b1b1b1 linear-gradient(90deg, transparent, #888);
    border-radius: 10px;
    /* box-shadow: inset 0 3px 4px #000; */
    margin: 5% auto;
    /* padding: 5px; */
    /* border: 3px solid #232323; */
    width: 90%;
    background: #1c1c1c;
    /* height: 35%; */
  }
  .nav-1 {
    /*padding: 5px;*/
    margin-top: 14%;
    margin-left: 10%;
    width: 100%;
    display: none;
    position: relative;
    text-align: left;
  }
  .nav-1 img {
    width: 110px !important;
  }
  .nav-2 {
    padding: 5px;
    margin-top: 15%;
    margin-left: 15%;
    width: 127px;
    position: absolute;
    width: 100%;
    text-align: left;
  }

  .handle {
    background: #444;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 5px #000;
    position: absolute;
    top: 50px;
    left: 28px;
    width: 50px;
    height: 8px;
  }

  #donothing {
    /* box-shadow: inset 0 3px 4px #000; */
    margin-bottom: 5px;
    margin-top: 10px;
    /* background: black; */
    border-radius: 5px;
    border: 2px solid #434343;
    /* background-color: #e5e5f7; */
    background-color: rgb(1, 39, 3, 1);
    opacity: 1;
    background-image: linear-gradient(0deg, #042d01 70%, #0a0a0a 50%);
    background-size: 18px 3px;
    padding: 7px;
    width: 100%;
    /* font-size: 26px; */
    text-align: center;
  }

  .vertical-lines {
    display: flex;
  }

  .control-1 {
    margin: 5px;
    /*box-shadow: inset 0 3px 4px #000;*/
    /* border-radius: 10px; */
    /* border: 10px solid #2b2b2b; */
    /*background: #0e0e0e;*/
    color: #4cf038;
  }
  .line {
    border-left: 3px solid #3e3e3e;
    height: 220px;
    overflow: hidden;
    margin-left: 6px;
  }

  .line:first-child {
    border-left: none;
  }
  #speaker {
    background-image: url("../assets/Frame-mob.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    height: 13vh;
    width: 100%;
    display: flex;
    overflow: hidden !important;
    margin-bottom: 2px;
    justify-content: center;
    align-items: center;
    /*  border-radius: 0px;
      box-shadow: inset 0 3px 4px #000;
      margin: 5% auto;
      background: #000;   
  ;
      width: 90%;
      height: 30%;
      border: 2px solid #5c5c5c; */
    /* margin: 5% auto;*/
  }

  .hole {
    background: #000;
    border-left: 6px solid green;
  }

  #legs {
    background: linear-gradient(
      90deg,
      transparent 10%,
      #210 10%,
      #210 25%,
      transparent 25%,
      transparent 75%,
      #210 75%,
      #210 90%,
      transparent 90%,
      transparent
    );
    position: absolute;
    left: 0;
    bottom: -44px;
    width: 100%;
    height: 40px;
  }

  /* ##Functions# */

  .text-head1 {
    background: var(
      --Gradent,
      linear-gradient(
        180deg,
        #fd8d23 -1.34%,
        #fae61c 10.31%,
        #eef73b 20.95%,
        #4cf038 42.23%,
        #33c6f6 65.04%,
        #559cfb 73.14%,
        #7475ff 80.74%,
        #d457ff 100%
      )
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    /* box-shadow: 0 5px #141414; */
    transform: translateY(4px);
  }

  /* ##Functions# */
  .text-head {
    background: var(
      --Gradent,
      linear-gradient(
        180deg,
        #fd8d23 -1.34%,
        #fae61c 10.31%,
        #eef73b 20.95%,
        #4cf038 42.23%,
        #33c6f6 65.04%,
        #559cfb 73.14%,
        #7475ff 80.74%,
        #d457ff 100%
      )
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .trasition-1 {
    transform: translate(0%, 6%);
    text-align: -webkit-center;
    text-align: -moz-center;
    padding: 0px 50px 0px 50px;
  }
  .trasition-3 {
    transform: translate(0%, 25%);
    text-align: -webkit-center;
    padding: 0px 50px 0px 50px;
  }
  .gif-img {
    width: 80px !important;
  }

  #textcolorabout {
    color: #ffffff;
    filter: drop-shadow(2px 2px 0px rgba(248, 47, 155, 1));
    text-transform: uppercase;
    line-height: 1.5;
    /* margin-left: 150px;  */
    /*margin-top: 50px;*/
  }

  #textcolortoken {
    color: #fff;
    /* margin-left: 150px;*/
    padding-bottom: 5px !important;
    margin-top: 1%;
    margin-top: 10px;
    filter: drop-shadow(2px 2px 0px rgba(248, 47, 155, 1));
    text-transform: uppercase;
    font-family: mySirstFont !important;
  }
  .pb-5 {
    padding-bottom: 5px !important;
  }

  .roadmapimg {
    display: none !important;
  }

  #textcolorroadmap {
    color: red;
    text-transform: uppercase;
    /* margin-left: 150px;*/
    margin-top: 10px;
    filter: drop-shadow(2px 2px 0px rgba(248, 47, 155, 1));
    font-size: 30px;
    font-family: mySirstFont !important;
  }
  .bordersec p {
    padding: 10px 10px !important;
  }
  .text-head2 {
    font-size: 15px !important;
  }
  .text-head {
    font-size: 14px !important;
  }

  #textcolorsocial {
    font-size: 22px;
    color: #fff;
    margin-top: 10px;
    font-family: mySirstFont !important;
  }

  #playbutton {
    color: rgb(7, 6, 6);
    font-weight: bolder;
  }

  #imagetext {
    font-size: larger;
    color: white;
    text-align: center;
    padding: 0px;
    /*margin-left: 34px;*/
  }
  /* Create three equal columns that floats next to each other */
  .column {
    float: left;
    width: 33.33%;
    padding: 10px;
    /* height: 300px; /* Should be removed. Only for demonstration */
  }

  /* Clear floats after the columns */
  .row:after {
    content: "";
    display: table;
    clear: both;
  }
  .row {
    /* padding:2px; */
    justify-content: center;
    align-items: center;
    --bs-gutter-x: 0.5rem !important;
    text-align: -webkit-center;
    /* height: 100%; */
  }
  .road-map {
    padding-top: 25px;
    font-size: 20px !important;
    padding-bottom: 25px;
    margin-top: -36px;
    /* margin-left: 15px;
        margin-right: 15px; */
    background: black;
    /* margin-right: 15px; */
    border: 2px solid #63ef39;
    width: 85%;
    box-shadow: 2px 6px 8px #f82f9b;
    border: 2px solid transparent;
    border-image-slice: 1;
    border-image-source: linear-gradient(
      180deg,
      #fd8d23 -1.34%,
      #fae61c 10.31%,
      #eef73b 20.95%,
      #4cf038 42.23%,
      #33c6f6 65.04%,
      #559cfb 73.14%,
      #7475ff 80.74%,
      #d457ff 100%
    );
  }
  .road-map-txt {
    font-size: 16px !important;
    padding-top: 12px;
    padding-bottom: 26px;
    padding-left: 2px;
    padding-right: 2px;
  }
  .road-map-txt {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 2px;
    padding-right: 2px;
  }
  .road-map-dash {
    border: 2px solid transparent;
    border-image-source: linear-gradient(
      180deg,
      #fd8d23 -1.34%,
      #fae61c 10.31%,
      #eef73b 20.95%,
      #4cf038 42.23%,
      #33c6f6 65.04%,
      #559cfb 73.14%,
      #7475ff 80.74%,
      #d457ff 100%
    );
    border-image-slice: 1;
    padding: 10px 10px 20px 10px;
    height: 100%;
    width: 85%;
    margin-bottom: 50px;
    margin-top: 20px;
  }
  .roadmapimg {
    padding-bottom: 10px;
  }

  .trasition-2 {
    transform: translate(0%, 25%);
  }
  .social-1 {
    text-align: right;
    position: absolute;
    display: flex;
    margin-top: -20px;
    right: 30px;
  }
  .overlay {
    position: fixed;
    top: 0;
    backdrop-filter: blur(3px);
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0 0 0 / 65%);
    z-index: 1000;
    display: flex;
    pointer-events: all;
    justify-content: center;
    align-items: center;
  }

  .overlay-content {
    text-align: center;
    color: white;
    pointer-events: all;
  }

  .overlay-close {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    cursor: pointer;
  }

  /* Glitch */
  /*
    #glitch-background {
      height: 850px;
      width: 850px;
      /* position: fixed; 
      top: 0;
      left: 0;
      background-color: #0d0c0c;
      background-image: radial-gradient(circle, #5d5d5d 1px, black 1px);
      background-size: 10px 10px;
      animation: glitch 1s infinite linear;
      overflow: hidden;
    } 
  
  @keyframes glitch {
    0%, 100% {
      background-position: 0 0, 0 0, 0 0;
    }
    10% {
      background-position: -5px -5px, 5px 5px, -5px 5px;
    }
    20% {
      background-position: 5px -5px, -5px 5px, 5px 5px;
    }
    30% {
      background-position: -5px 5px, 5px -5px, -5px -5px;
    }
    40% {
      background-position: 5px 5px, -5px -5px, 5px -5px;
    }
    50% {
      background-position: 0 0, 0 0, 0 0;
    }
    60% {
      background-position: -5px -5px, 5px 5px, 5px -5px;
    }
    70% {
      background-position: 5px 5px, -5px 5px, -5px -5px;
    }
    80% {
      background-position: -5px 5px, 5px -5px, 5px 5px;
    }
    90% {
      background-position: 5px -5px, -5px -5px, -5px 5px;
    }
  }  */

  .noise {
    width: 100%;
    height: 100%;
    opacity: 0.8;
    background: linear-gradient(
        to bottom,
        transparent,
        #aaa4,
        #8881,
        #6664,
        #4445,
        #2228,
        #4443,
        transparent
      ),
      repeating-linear-gradient(transparent 0 2px, #25242950 2px 4px);
    -webkit-animation: moveBand 8s linear infinite;
    animation: moveBand 8s linear infinite;
    z-index: 4;
    position: absolute;
  }

  @-webkit-keyframes moveBand {
    0% {
      background-position-y: 0, 0;
    }
    100% {
      background-position-y: -221px, -150px;
    }
  }

  @keyframes moveBand {
    0% {
      background-position-y: 0, 0;
    }
    100% {
      background-position-y: -221px, -150px;
    }
  }
  .img-res {
    text-align: center;
    width: 100%;
    height: 100%;
    text-align: -webkit-center;
    text-align: -moz-center;
    scale: 0.8;
  }
  .playvideo {
    width: 100%;
    height: 70vh;
    object-fit: scale-down;
  }
  .img-res img {
    width: 250px;
    scale: 1 !important;
  }
  .about-para {
    margin-bottom: 10px;
    margin-top: 15px;
  }
  .road-map-text {
    transform: translate(0%, 0.5%);
    padding: 40px;
  }

  .btn-primary {
    background-color: #09bd1b;
    padding: 5px 50px 5px 50px;
  }
  .do-nothing-button {
    display: flex !important;
  }
  .btn-outline {
    border: 2px solid;
    padding: 8px 28px;
    color: #0abd1c;
  }

  .welcome-info {
    width: 590px;
    background: #000000e0;
    padding: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
    border: 2px solid #0abd1c;
    margin: 50px;
  }
  .btn-do {
    transform: translate(23%, 0%);
    margin-top: 5px;
  }
  .supply-p {
    font-size: 14px !important;
    /*border: .nav-2px solid green;*/
    padding: 0px 0px 10px 0px;
  }
  bordersec h3 {
    padding: 25px 0px;
    font-family: mySirstFont;
    border-bottom: 3px DASHED #c75cd8;
    color: rgba(250, 230, 29, 1);
  }
  .img-social {
    display: flex;
    justify-content: center; /* Centers child elements horizontally */
    align-items: center; /* Centers child elements vertically */
    padding: 10px;
  }

  .img-social ul {
    display: flex;
    text-align: -webkit-center;
    align-items: center;
  }

  .img-social ul li img {
    width: 30px;
    margin-right: 10px;
    cursor: pointer;
  }

  .trasition-1 h3 {
    color: #ffffff;
  }

  .social-tag {
    font-size: 14px;
    padding: 5px;
    background: var(
      --Gradent,
      linear-gradient(
        180deg,
        #ffdb2e -1.34%,
        #b5ab43 10.31%,
        #eef73b 20.95%,
        #fff700 42.23%,
        #d3d062 65.04%,
        #ffb000 73.14%,
        #ff0000 80.74%,
        #ff0000 100%
      )
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .tv {
    /* background: #210; */
    /* border-radius: 0px; */
    /* box-shadow: inset 0 5px 5px #121212, inset 5px 0 5px #555, inset -5px 0 5px #555, inset 0 -5px 5px #555; */
    position: relative;
    /* margin: 0px -30px auto; */
    /* margin-top: -50px; */
    z-index: 1;
    width: 100%;
    /*padding: 10px;*/
    /*transform: translate(0%, -360px);*/
  }
  .social-links {
    /*text-align: -webkit-right !important;*/
    margin-left: 5px;
  }
  .social-links ul {
    display: flex;
    color: azure;
    /* width: 15%;
  */
  }
  ol,
  ul {
    padding-left: 0rem !important;
  }

  .social-links ul li {
    margin-right: 10px;
    float: right;
  }
  .social-links ul li img {
    width: 30px;
    cursor: pointer;
  }
  .img-z {
    z-index: 9;
  }

  .about-gif-part {
    text-align: -webkit-center;
    margin-top: 15px;
  }
  .about-gif-part img {
    margin-top: 30px;
    width: 100px;
  }

  .about-trasition-1 {
    /* transform: translate(0%, 0%); */
    padding: 0px 40px 0px 40px;
  }

  .trasition-2 {
    transform: translate(0%, 0%);
    padding: 0px 30px 0px 30px;
  }

  /* .supply-part {
    align-items: baseline;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
  } */
  .supply-ocb {
    text-align: -webkit-center;
    margin-top: 10px;
  }

  .rope-img {
    text-align: -webkit-center;
    margin-top: 0px;
    margin-right: -20px;
    width: 100%;
    transform: translateX(0%);
    bottom: 0%;
    width: 100%;
    left: 0;
    position: absolute;
    display: flex;
    padding: 10px 0px;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  .rope-img img {
    width: 50%;
  }
}

/* @media screen and (min-width: 1250px) {
  #controls {
    border-radius: 10px;
    width: 20% !important;
    height: 100%;
    margin-left: 20px;
  }

} */
/* @media screen and (min-height: 350px) and (max-height: 680px) {
  #screen {
    height: 65vh !important;
  }
  #tv {
    height: 65vh !important;
  }
} */

@media screen and (min-width: 993px) and (max-width: 1199px) {
  .tv {
    /* background: #210; */
    /* border-radius: 0px; */
    /* box-shadow: inset 0 5px 5px #121212, inset 5px 0 5px #555, inset -5px 0 5px #555, inset 0 -5px 5px #555; */
    position: relative;
    margin: 4px 0px auto;
    margin-top: 0px;
    z-index: 1;
    width: 1200px;
    padding: 2px;
    /* transform: translate(0%, -360px); */
  }
  .navi {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    /*border: 2px solid #0e7616;*/
    padding: 5px;
    gap: 10;
    justify-content: center;
  }
  #speaker {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    /* height: 300px; */
    display: flex;
    /* background-size: 100% 100%; */
    justify-content: center;
    align-items: center;
    /* margin-bottom: 20px; */
    /* margin: 5% auto; */
  }
  .bg-span {
    margin-left: 0px;
    background-color: #58c340;
    color: #ffffff !important;
    padding: 2px;
  }
  .p50 {
    padding: 0px 50px;
  }
  .justify-content {
    justify-content: center;
  }
  .mob-res {
    display: none !important;
  }
  #textcolorabout {
    color: #ffffff;
    /* margin-left: 150px;  */
    /*margin-top: 50px;*/
    color: #ffffff;
    font-family: mySirstFont !important;
    /* margin-left: 150px; */
    margin-top: 40px;
    font-size: 28px !important;
  }

  .about-trasition-1 {
    /* transform: translate(0%, 21%) !important; */
    padding: 0px 75px 0px 75px;
  }
  .trasition-1 {
    transform: translate(0%, 5%);
    text-align: -webkit-center;
    text-align: -moz-center;
    padding: 0px 50px 0px 50px;
  }
  .trasition-3 {
    transform: translate(0%, 16%);
    text-align: -webkit-center;
    padding: 0px 100px 0px 100px;
  }

  #scroller {
    font-weight: bold;
    font-size: 350%;
    text-align: justify;
    overflow: hidden;
    height: 50em;
    width: 40em;
    position: absolute;
    bottom: 0;
    left: 85%;
    margin-left: -10em;
    z-index: 0;
    transform: perspective(350px) rotateX(25deg);
    transform-origin: 50% 100%;
  }
  .about-para1 {
    font-size: 40px !important;
  }

  #glitch-background {
    /* display: flex; */
    align-items: center;
    justify-content: center;
    width: 100%;
    /* height: 60%; */
  }
  .nav-1 {
    padding: 5px;
    margin-top: 7%;
    margin-left: 7%;
    width: 127px;
    position: absolute;
  }
  .tv {
    /* background: #210; */
    /* border-radius: 0px; */
    /* box-shadow: inset 0 5px 5px #121212, inset 5px 0 5px #555, inset -5px 0 5px #555, inset 0 -5px 5px #555; */
    position: relative;
    margin: 0px 0px auto;
    margin-top: 0px;
    z-index: 1;
    width: 100%;
    padding: 2px;
    /* transform: translate(0%, -440px); */
  }
  #tv {
    background: radial-gradient(#060606, #313131, #101010);
    border-radius: 0px;
    /* box-shadow: inset 0 5px 5px #121212, inset 5px 0 5px #555, inset -5px 0 5px #555, inset 0 -5px 5px #555; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: right;
    align-items: center;
    margin: 00px auto;
    position: relative;
    padding: 10px;
    height: 95vh;
    /* width: 990px !important; */
    z-index: 0;
    /* height: 660px !important; */
    border: 20px solid transparent;
    border-image: url("../assets/Image-Recovered.png") 30 round;
  }
  #controls {
    border-radius: 10px;
    width: 18%;
    /* height: 100%; */
    margin-left: 10px;
  }
  .small-d {
    padding-right: 20px !important;
  }

  #screen {
    width: 80%;
    height: 100%; /* Set the height of the container to fill the viewport */
    background-image: url("../assets/glass-inside.png"); /* Replace 'your-image.jpg' with your image path */
    background-size: 100% 100%; /* Stretch the background image to cover the entire container */
    background-position: center; /* Center the background image */
    background-repeat: no-repeat; /* Prevent the background image from repeating */
    background-size: 100% 100%;
  }
  .ul-button li {
    /* border: 1px solid #6b6b6b75; */
    padding: 7px;
    cursor: pointer;
    list-style-type: none;
  }
  .btn-color h3 {
    font-size: 16px !important;
  }
  clain-h3 {
    font-size: 15px !important;
  }
  span {
    margin-left: 30px;
    color: aliceblue;
    font-size: 16px !important;
    text-transform: uppercase;
  }
  control-1 {
    margin: 0px !important;
    /* box-shadow: inset 0 3px 4px #000; */
    border-radius: 5px;
    border: 2px solid #232323;
    background: #1c1c1c;
  }
  #panel {
    margin: 2% auto;
    padding: 5px;
    /* border: 2px solid #232323; */
    width: 100%;
  }
}

@media screen and (min-width: 1200px) {
  .right-align {
    margin-right: 10px !important;
  }
  .text-1 {
    color: #43b736;
  }
  .text-ab {
    color: #fff;
  }
  .mob-res {
    display: none !important;
  }
  .justify-content {
    justify-content: center;
  }
}

@media screen and (max-width: 767px) {
  .text-1 {
    color: #43b736;
  }
  .rope-img img {
    width: 60%;
  }
  .bordersec1 p {
    /* padding: 25px 10px; */
    font-size: 1rem;
    text-align: center;
    color: #747474;
    text-transform: capitalize;
    height: 10vh;
    display: flex;
    align-items: center;
  }
  .bordersec1 h3 {
    padding: 35px 0px;
    font-family: mySirstFont;
    border-bottom: 3px DASHED #c75cd8;
    background: linear-gradient(
      264deg,
      #fd8d23 34.66%,
      #fae61c 49.31%,
      #eef73b 60.95%,
      #4cf038 77.23%,
      #33c6f6 84.04%,
      #559cfb 94.14%,
      #7475ff 75.74%,
      #d457ff 100%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 1.8rem !important;
  }
  .bordersec h3 {
    padding: 35px 0px;
    text-transform: uppercase;
    font-family: mySirstFont;
    border-bottom: 3px DASHED #c75cd8;
    color: rgba(250, 230, 29, 1);
  }
  .bordersec p {
    /* padding: 25px 10px; */
    font-size: 1rem;
    text-align: center;
    color: #747474;
    text-transform: capitalize;
    height: 16vh;
    display: flex;
    align-items: center;
  }
  .bordersec1 h6 {
    color: rgba(250, 230, 29, 1);
    text-transform: uppercase;
    font-size: 1.1rem;
  }
  .text-ab {
    color: #fff;
  }
  /* .rope-img img {
    display: none;
  } */
  .social-tag {
    font-size: 12px !important;
  }
  .header-line {
    font-size: 20px !important;
  }
  .gif-img {
    width: 60px !important;
  }
  .img-social {
    padding: 5px !important;
  }
  .pb-2 {
    padding-bottom: 5px !important;
  }
  #scroller {
    font-weight: bold;
    font-size: 350%;
    text-align: justify;
    overflow: hidden;
    height: 50em;
    width: 40em;
    position: absolute;
    bottom: 0;
    z-index: 0;
    transform: perspective(393px) rotateX(18deg);
    transform-origin: 50% 100%;
  }

  #content {
    position: absolute;
    top: -100%;
    animation: scoller 100s linear infinite;
  }
  .about-trasition-1 {
    /* transform: translate(0%, 15%); */
    padding: 0px 50px 0px 50px;
    margin-bottom: 40%;
  }
  .about-trasition {
    /* transform: translate(0%, 15%); */
    padding: 0px 50px 0px 50px;
  }
}
@media screen and (max-width: 576px) {
  .about-trasition-1 {
    /* transform: translate(0%, 10%); */
    padding: 0px 50px 0px 50px;
  }
}

@media screen and (min-width: 769px) and (max-width: 992px) {
  .text-1 {
    color: #43b736;
  }
  .text-ab {
    color: #fff;
  }
  .about-trasition-1 {
    /* transform: translate(0%, 50%); */
    padding: 0px 100px 0px 100px;
    margin-bottom: 40%;
  }
  .about-trasition {
    /* transform: translate(0%, 15%); */
    padding: 0px 50px 0px 50px;
  }

  .justify-content {
    justify-content: center;
  }
  .header-line {
    font-size: 30px !important;
    text-align: center;
    font-family: mySirstFont;
  }
  /* .rope-img {
    transform: translateX(0%);
    bottom: -40%;
    position: fixed;
  } */
  .header-line {
    /* font-size: 25px !important; */
    text-align: center;
    font-family: mySirstFont !important;
    /* margin-bottom: 40px; */
  }
  .trasition-2 {
    transform: translate(0%, 0%);
    padding: 0px 50px 0px 50px;
  }
  .text-head {
    font-size: 16px !important;
  }
  .road-map-text {
    transform: translate(0%, 0.5%);
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 10px;
    padding-bottom: 40px;
  }
  .text-head2 {
    font-size: 12px !important;
  }
  .supply-p {
    font-size: 25px !important;
  }
  .about-pad {
    margin-top: 10px !important;
  }
  .nav-1 {
    margin-top: 8%;
    margin-left: 10%;
    width: 100%;
    position: relative;
    text-align: left;
  }
}

@media screen and (min-width: 1551px) {
  .slider-track {
    display: flex;
    gap: 20px;
    transition: transform 0.5s ease-in-out;
  }
  .gif-size {
    width: 85%;
    margin-top: 10%;
  }
  .bg-span {
    margin-left: 0px;
    background-color: #58c340;
    color: #ffffff !important;
    padding: 2px;
  }
  .f18 {
    font-size: 28px !important;
    padding-top: 5px;
    padding-bottom: 5px;
    background: var(
      --Gradent,
      linear-gradient(
        180deg,
        #fd8d23 17.66%,
        #fae61c 18.31%,
        #eef73b 29.95%,
        #4cf038 42.23%,
        #33c6f6 65.04%,
        #559cfb 84.14%,
        #7475ff 66.74%,
        #d457ff 100%
      )
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .text-1 {
    color: #43b736;
  }
  .text-ab {
    color: #fff;
  }
  .slider-arrow {
    background: none;
    border: none;
    cursor: pointer;
    position: relative;
    /* top: 50%; */
    /* transform: translateY(-50%); */
    /* z-index: 1000; */
    margin-top: 25px;
  }
  .left-arrow {
    margin-right: 10px;
  }
  .right-arrow {
    margin-left: 10px;
  }
  .img-desk {
    width: 100%;
    height: auto;
  }

  body {
    margin: 0;
    padding: 0;
    height: 100vh;
    /* display: flex; */
    justify-content: center;
    align-items: center;
  }

  .slide {
    display: flex;
    gap: 10px;
  }
  .do-nothing {
    /* box-shadow: inset 0 3px 4px #000; */
    margin-bottom: 5px;
    /* background: black; */
    border-radius: 5px;
    border: 2px solid #434343;
    /* background-color: #e5e5f7; */
    background-color: rgb(1, 39, 3, 1);
    opacity: 1;
    background-image: linear-gradient(0deg, #042d01 70%, #0a0a0a 50%);
    background-size: 18px 3px;
    padding: 7px;
    width: 100%;
    font-size: 26px;
    text-align: center;
  }
  #navi-video {
    /* box-shadow: inset 0 3px 4px #000; */
    margin-bottom: 5px;
    /* background: black; */
    border-radius: 5px;
    border: 2px solid #434343;
    /* background-color: #e5e5f7; */
    background-color: rgb(1, 39, 3, 1);
    opacity: 1;
    background-image: linear-gradient(0deg, #042d01 70%, #0a0a0a 50%);
    background-size: 18px 3px;
    padding: 7px;
    width: 100%;
    font-size: 26px;
    text-align: center;
  }
  .justify-content {
    justify-content: center;
  }
  .mob-res {
    display: none !important;
  }
  .donothing {
    color: #1fdf08 !important;
    /* text-transform: uppercase; */
    font-size: 20px;
  }
  .intro-wallet {
    display: flex;
    position: absolute;
    z-index: 99999999;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    height: 75vh; /* Full height of the viewport */
    width: 100%;
  }
  .desk-wel {
    display: none !important;
  }
  .claim-1 {
    margin-left: 15px !important;
  }
  .starwars {
    margin: 0;
    /* background-color: #000; */
    overflow: hidden !important;
  }
  .starwars::after {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 80vh;
    /* background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 1) 61%,
        rgba(0, 0, 0, 0.54) 79%,
        rgba(0, 0, 0, 0) 100%
      ); */
    z-index: 99999;
  }
  .stars {
    position: absolute;
    width: 1px;
    height: 1px;
    background-color: #fff;
    z-index: 2;
  }
  .intro {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    font-weight: 500;
    font-size: 25px;
    padding: 30px;
    width: 80%;
    opacity: 0;
    animation: intro 2s ease-out 0.5s;
  }
  @keyframes intro {
    0% {
      opacity: 0;
    }
    20% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  #scroller {
    font-weight: bold;
    font-size: 356%;
    text-align: justify;
    overflow: hidden;
    height: 100em;
    width: 100em;
    position: absolute;
    bottom: 0;
    /* left: 40%; */
    /* margin-left: -10em; */
    z-index: 0;
    transform: perspective(260px) rotateX(19deg);
    transform-origin: 50% 100%;
  }
  #scroller::after {
    position: absolute;
    content: "";
    bottom: 60%;
    left: 0;
    right: 0;
    top: 0;
  }
  #content {
    position: absolute;
    top: -100%;
    animation: scoller 100s linear infinite;
  }
  #content p {
    font-size: 40px;
    text-align: center;
  }
  @keyframes scoller {
    0% {
      top: 100%;
    }
    100% {
      top: -100%;
    }
  }

  body {
    background: #453522 !important;
    font-family: myFirstFont !important;
    overflow: hidden;
  }
  .about-para1 {
    font-size: 55px !important;
    padding-bottom: 22px;
  }
  .about-pad p {
    font-size: 23px;
    padding: 0px 80px;
  }
  .claim-h3 {
    line-height: 35px;
    padding: 1px !important;
  }
  .center-content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50vh;
    /* height: 70%; */
  }
  .mob-desk {
    display: none !important;
  }
  h3 {
    font-size: 23px !important;
  }
  .meme-img img {
    background-position: center;
    width: 100%;
  }
  .inner-text {
    text-align: center;
    font-size: 17px;
    color: #29585bff;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* overflow: scroll !important; */
    /*padding:15px;*/
  }
  .inner-text1 {
    text-align: center;
    font-size: 17px;
    color: #29585bff;
    width: 100%;
    height: 100%;

    /* overflow: scroll !important; */
    /*padding:15px;*/
  }
  .navbar-1 {
    display: none !important;
  }
  .img-res {
    width: 100%;
    height: 100%;
    transform: translate(0%, 35%);
  }
  .ul-button {
    /* font-weight: bolder; */
    border: 2px solid #6b6b6b1c;
    padding-left: 0px;
  }

  .ul-button li {
    /* border: 1px solid #6b6b6b75; */
    padding: 12px;
    cursor: pointer;
    list-style-type: none;
    font-size: 17px;
    /* background: var(--Gradent, linear-gradient(180deg, #FD8D23 -1.34%, #FAE61C 10.31%, #EEF73B 20.95%, #4CF038 42.23%, #33C6F6 65.04%, #559CFB 73.14%, #7475FF 80.74%, #D457FF 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
  }
  .ul-button li img {
    position: absolute;
    display: flex;
  }

  span {
    margin-left: 30px;
    color: #ffffff;
    text-transform: uppercase;
  }
  .hippe {
    margin-left: 0px !important;
  }
  h2 {
    font-family: mySirstFont;
    z-index: 1 !important;
  }
  .header-line {
    font-family: mySirstFont;
    background: linear-gradient(
      180deg,
      #fd8d23 -1.34%,
      #fae61c 10.31%,
      #eef73b 20.95%,
      #4cf038 42.23%,
      #33c6f6 65.04%,
      #559cfb 73.14%,
      #7475ff 80.74%,
      #d457ff 100%
    );
    -webkit-background-clip: text; /* Apply gradient to text */
    -webkit-text-fill-color: transparent; /* Hide original text */
  }

  .shadow {
    position: absolute;
    top: 15px;
    left: 50px;
    z-index: -1;
    font-size: 48px;
    font-weight: bold;
    color: rgba(255, 0, 255, 0.5);
    font-family: myFirstFont !important;
    text-shadow: 0px 4px 4px #f82f9b;
  }

  #tv {
    background: radial-gradient(#060606, #313131, #101010);
    border-radius: 0px;
    /* box-shadow: inset 0 5px 5px #121212, inset 5px 0 5px #555, inset -5px 0 5px #555, inset 0 -5px 5px #555; */
    display: flex;
    /* flex-direction: row; */
    justify-content: space-between;
    align-content: right;
    align-items: center;
    margin: 0px auto;
    position: relative;
    padding: 10px;
    width: 100%;
    z-index: 0;
    height: 94vh;
    border: 20px solid transparent;
    border-image: url("../assets/Image-Recovered.png") 30 round;
  }

  .inner-glass {
    /* background: #1a1a1a linear-gradient(transparent, #333); */
    /*border-top: 1px solid #433;*/
    /* border-bottom: 1px solid #433; */
    /*border-radius: 50% / 10%;
        box-shadow: inset 0 1px 6px #121212, inset 6px 0 5px #555, inset -6px 0 7px #555, inset 0 6px 3px #555; */
    /* margin: 2% auto; */
    overflow: hidden;
    pointer-events: all;
    position: relative;
    width: 100%;
    height: 100%;
    /* box-shadow: 0 2px 2px #888, 2px 0 2px #444, -2px 0 2px #444, 0 -2px 2px #111;*/
  }

  #screen {
    /* border: 2px solid #969696ff;*/
    border-radius: 0px;
    /*box-shadow: 0 2px 2px #888, 2px 0 2px #444, -2px 0 2px #444, 0 -2px 2px #111; */
    background: linear-gradient(
      86deg,
      rgb(47 46 46) 1%,
      rgb(131 132 135 / 29%) 10%,
      rgb(0 0 0) 50%,
      rgb(133 133 133 / 25%) 90%,
      rgb(0 0 0) 100%
    );
    /*box-shadow: inset 0 4px 5px #444, inset 4px 0 5px #666, inset -2px 0 5px #888, inset 0 0px 5px #888; */
    width: 100%;
    height: 100%;
    background-image: url("../assets/glass-inside.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    pointer-events: none;
  }

  #glass {
    background: #000;
    /*border-top: 1px solid #433;*/
    /*border: 5px solid #000;*/
    /* border-bottom: 1px solid #433; */
    /* border-radius: 50% / 10%;*/
    /*box-shadow: inset 0 2px 4px #000, inset 0 3px 4px #000, inset -7px 0 -2px #000, inset -8px 0 6px #000;*/
    margin: 2% auto;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 95%;
    z-index: -1;
  }

  #controls {
    border-radius: 10px;
    width: 15%;
    /* height: 100%; */
    margin-left: 20px;
  }

  #panel {
    background: #b1b1b1 linear-gradient(90deg, transparent, #888);
    border-radius: 10px;
    box-shadow: inset 0 3px 4px #000;
    margin: 2% auto;
    padding: 5px;
    border: 2px solid #232323;
    width: 100%;
    background: #1c1c1c;
    /* height: 35%; */
  }
  .nav-1 {
    padding: 5px;
    margin-top: 4%;
    margin-left: 9%;
    width: 100%;
    position: absolute;
  }
  .nav-2 {
    padding: 5px;
    margin-top: 5%;
    margin-left: 6%;
    width: 127px;
    position: absolute;
  }

  .handle {
    background: #444;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 5px #000;
    position: absolute;
    top: 50px;
    left: 28px;
    width: 50px;
    height: 8px;
  }

  #navi-video {
    /* box-shadow: inset 0 3px 4px #000; */
    margin-bottom: 5px;
    /* background: black; */
    border-radius: 5px;
    border: 2px solid #434343;
    /* background-color: #e5e5f7; */
    background-color: rgb(1, 39, 3, 1);
    opacity: 1;
    background-image: linear-gradient(0deg, #042d01 70%, #0a0a0a 50%);
    background-size: 18px 3px;
    padding: 7px;
    width: 100%;
    font-size: 26px;
    text-align: center;
  }
  .btn-color {
    background: none;
    border: none;
  }
  .btn-color h3 {
    color: #09bd1b;
    /* margin-top: 7px;
    */
  }
  .vertical-lines {
    display: flex;
  }

  .control-1 {
    margin-top: 5px;
    /*box-shadow: inset 0 3px 4px #000;*/
    /* border-radius: 5px;
      border: 2px solid #232323; */
    background: #1c1c1c;
    padding-top: 5px;
  }
  .line {
    border-left: 3px solid #3e3e3e;
    height: 220px;
    overflow: hidden;
    margin-left: 6px;
  }

  .line:first-child {
    border-left: none;
  }
  #speaker {
    background-image: url("../assets/wallet-p.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 300px; /* Set your desired height */
    display: flex;
    background-size: 100% 100%;
    justify-content: center;
    align-items: center;
    /*margin-bottom: 20px;*/
    /*  border-radius: 0px;
        box-shadow: inset 0 3px 4px #000;
        margin: 5% auto;
        background: #000;   
    ;
        width: 90%;
        height: 30%;
        border: 2px solid #5c5c5c; */
    /* margin: 5% auto;*/
  }

  .hole {
    background: #000;
    border-left: 6px solid green;
  }

  #legs {
    background: linear-gradient(
      90deg,
      transparent 10%,
      #210 10%,
      #210 25%,
      transparent 25%,
      transparent 75%,
      #210 75%,
      #210 90%,
      transparent 90%,
      transparent
    );
    position: absolute;
    left: 0;
    bottom: -44px;
    width: 100%;
    height: 40px;
  }

  /* ##Functions# */

  .text-head1 {
    background: var(
      --Gradent,
      linear-gradient(
        180deg,
        #fd8d23 -1.34%,
        #fae61c 10.31%,
        #eef73b 20.95%,
        #4cf038 42.23%,
        #33c6f6 65.04%,
        #559cfb 73.14%,
        #7475ff 80.74%,
        #d457ff 100%
      )
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    /*box-shadow: 0 5px #141414;*/
    transform: translateY(4px);
  }

  /* ##Functions# */
  .text-head {
    background: var(
      --Gradent,
      linear-gradient(
        180deg,
        #fd8d23 -1.34%,
        #fae61c 10.31%,
        #eef73b 20.95%,
        #4cf038 42.23%,
        #33c6f6 65.04%,
        #559cfb 73.14%,
        #7475ff 80.74%,
        #d457ff 100%
      )
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .trasition-1 {
    /* transform: translate(0%, 10%); */
    text-align: -webkit-center;
    text-align: -moz-center;
    padding: 0px 150px 0px 150px;
  }
  .trasition-3 {
    transform: translate(0%, 10%);
    text-align: -webkit-center;
    padding: 0px 100px 0px 100px;
  }
  .trasition-4 {
    /* transform: translate(0%, 10%); */
    text-align: -webkit-center;
    padding: 0px 100px 0px 100px;
  }
  .claim-reward {
    font-size: 20px;
  }
  #textcolorabout {
    color: #ffffff;
    /* margin-left: 150px;  */
    /*margin-top: 50px;*/
  }

  #textcolorsocial {
    color: red;
    /*margin-left: 150px;*/
    margin-top: 50px;
  }
  #textcolorroadmap span {
    font-size: 15px !important;
  }
  #textcolorroadmap {
    color: red;
    /* margin-left: 150px;*/
    margin-bottom: 25px;
    font-size: 55px;
    font-family: mySirstFont;
    text-align: center;
  }
  #textcolortoken {
    color: red;
    /* margin-left: 150px; */
    margin-bottom: 10px;
    font-size: 55px;
    font-family: mySirstFont;
  }

  #textcolorsocial {
    font-size: 55px;
    color: #fff;
    margin-top: 20px;
    margin-bottom: 15px;
  }

  #playbutton {
    color: rgb(7, 6, 6);
    font-weight: bolder;
  }

  #imagetext {
    font-size: larger;
    color: #4cf038;
    /*margin-left: 34px;*/
  }
  /* Create three equal columns that floats next to each other */
  .column {
    float: left;
    width: 33.33%;
    padding: 10px;
    /* height: 300px; /* Should be removed. Only for demonstration */
  }

  /* Clear floats after the columns */
  .row:after {
    content: "";
    display: table;
    clear: both;
  }
  .row {
    /*padding: 20px;*/
    text-align: -webkit-center;
  }

  .road1::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 4px;
    background: none;
    transform: translate(-50%, -50%);
    z-index: -1;
  }

  /* Line part 1: From left div to the center */
  .road1::before {
    content: "";
    position: absolute;
    top: 50%;
    left: calc(
      50% - 50px - 20px
    ); /* Center minus half width of the div and margin */
    width: 20px; /* Width of the horizontal segment */
    height: 2px;
    background-color: black;
    transform: translateY(-50%);
  }

  .road-2 {
    margin-top: -150px !important;
  }
  /* Line part 2: Vertical segment down from the center div */
  .road-min::after {
    content: "";
    position: absolute;
    bottom: -10px; /* Adjust as needed */
    left: 50%;
    width: 2px;
    height: 20px; /* Height of the vertical segment */
    background-color: black;
    transform: translateX(-50%);
  }

  /* Line part 3: From right div to the center */
  .road1::after {
    content: "";
    position: absolute;
    top: 50%;
    left: calc(50% + 50px); /* Center plus half width of the div */
    width: 20px; /* Width of the horizontal segment */
    height: 2px;
    background-color: black;
    transform: translateY(-50%);
  }
  .road1 {
    align-items: center;
  }
  .road-map {
    padding-top: 25px;
    font-size: 20px !important;
    padding-bottom: 25px;
    margin-top: -36px;
    /* margin-left: 15px;
        margin-right: 15px; */
    background: black;
    /* margin-right: 15px; */
    border: 2px solid #63ef39;
    width: 85%;
    box-shadow: 2px 6px 8px #f82f9b;
    border: 2px solid transparent;
    border-image-slice: 1;
    border-image-source: linear-gradient(
      180deg,
      #fd8d23 -1.34%,
      #fae61c 10.31%,
      #eef73b 20.95%,
      #4cf038 42.23%,
      #33c6f6 65.04%,
      #559cfb 73.14%,
      #7475ff 80.74%,
      #d457ff 100%
    );
  }
  .road-map-txt {
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 19px;
    padding: 10px;
  }
  .road-map-dash {
    border: 2px solid transparent;
    border-image-source: linear-gradient(
      180deg,
      #fd8d23 -1.34%,
      #fae61c 10.31%,
      #eef73b 20.95%,
      #4cf038 42.23%,
      #33c6f6 65.04%,
      #559cfb 73.14%,
      #7475ff 80.74%,
      #d457ff 100%
    );
    border-image-slice: 1;
    padding: 10px;
    height: 100%;
    width: 85%;
  }

  .roadmapimg {
    padding: 15px;
  }
  .social-1 {
    text-align: right;
    position: absolute;
    display: flex;
    margin-top: -20px;
    right: 30px;
  }

  /* Glitch */
  /*
      #glitch-background {
        height: 850px;
        width: 850px;
        /* position: fixed; 
        top: 0;
        left: 0;
        background-color: #0d0c0c;
        background-image: radial-gradient(circle, #5d5d5d 1px, black 1px);
        background-size: 10px 10px;
        animation: glitch 1s infinite linear;
        overflow: hidden;
      } 
    
    @keyframes glitch {
      0%, 100% {
        background-position: 0 0, 0 0, 0 0;
      }
      10% {
        background-position: -5px -5px, 5px 5px, -5px 5px;
      }
      20% {
        background-position: 5px -5px, -5px 5px, 5px 5px;
      }
      30% {
        background-position: -5px 5px, 5px -5px, -5px -5px;
      }
      40% {
        background-position: 5px 5px, -5px -5px, 5px -5px;
      }
      50% {
        background-position: 0 0, 0 0, 0 0;
      }
      60% {
        background-position: -5px -5px, 5px 5px, 5px -5px;
      }
      70% {
        background-position: 5px 5px, -5px 5px, -5px -5px;
      }
      80% {
        background-position: -5px 5px, 5px -5px, 5px 5px;
      }
      90% {
        background-position: 5px -5px, -5px -5px, -5px 5px;
      }
    }  */

  .noise {
    width: 100%;
    height: 100%;
    opacity: 0.7;
    background: linear-gradient(
        to bottom,
        transparent,
        #aaa4,
        #8881,
        #6664,
        #4445,
        #2228,
        #4443,
        transparent
      ),
      repeating-linear-gradient(transparent 0 2px, #25242950 2px 4px);
    -webkit-animation: moveBand 8s linear infinite;
    animation: moveBand 8s linear infinite;
    z-index: 1;
    pointer-events: none;
    position: absolute;
  }

  @-webkit-keyframes moveBand {
    0% {
      background-position-y: 0, 0;
    }
    100% {
      background-position-y: -221px, -150px;
    }
  }

  @keyframes moveBand {
    0% {
      background-position-y: 0, 0;
    }
    100% {
      background-position-y: -221px, -150px;
    }
  }
  .img-res {
    text-align: center;
    width: 100%;
    height: 100%;
    text-align: -webkit-center;
    text-align: -moz-center;
  }
  .about-para {
    margin-bottom: 5px;
    margin-top: 10px;
  }
  .road-map-text {
    /*transform: translate(0%, 25%);*/
    padding-left: 150px;
    padding-right: 150px;
  }

  .btn-primary {
    background-color: #09bd1b;
    padding: 5px 50px 5px 50px;
  }
  .do-nothing-button {
    display: flex !important;
  }

  .btn-do {
    transform: translate(23%, 0%);
    margin-top: 5px;
  }
  .supply-p {
    font-size: 55px;
    /*border: 1px solid green;*/
    padding: 10px;
  }
  .img-social {
    display: flex;
    justify-content: center; /* Centers child elements horizontally */
    align-items: center; /* Centers child elements vertically */
    padding: 20px;
  }

  .img-social ul {
    display: flex;
    text-align: -webkit-center;
    align-items: center;
    list-style-type: none;
  }

  .img-social ul li img {
    width: 40px;
    margin-right: 10px;
    cursor: pointer;
  }

  .trasition-1 h3 {
    color: #ffffff;
  }
  .h-size {
    font-size: 90px;
  }
  .social-tag p {
    font-size: 20px;
    padding: 0px 150px;
  }
  .social-tag {
    font-size: 16px;
    padding: 15px;
    background: var(
      --Gradent,
      linear-gradient(
        180deg,
        #ffdb2e -1.34%,
        #b5ab43 10.31%,
        #eef73b 20.95%,
        #fff700 42.23%,
        #d3d062 65.04%,
        #ffb000 73.14%,
        #ff0000 80.74%,
        #ff0000 100%
      )
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  ol,
  ul,
  dl {
    margin-bottom: 0px !important;
  }
  ol,
  ul {
    padding-left: 0rem !important;
  }
  .navi {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    /*border: 2px solid #0e7616;*/
    padding: 15px;
    gap: 10;
    justify-content: center;
  }
  .navi h3 {
    font-size: 18px !important;
    padding: 18px;
  }

  .tv {
    /* background: #210; */
    /* border-radius: 0px; */
    /* box-shadow: inset 0 5px 5px #121212, inset 5px 0 5px #555, inset -5px 0 5px #555, inset 0 -5px 5px #555; */
    position: relative;
    /* margin: 5px 0px 5px 0px; */
    padding: 10px;
    /* margin-top: -113px; */
    z-index: 1;
    width: 100%;
    /* padding: 10px; */
    /* transform: translate(0%, -450px); */
  }
  .social-links {
    text-align: -webkit-right !important;
  }
  .social-links ul {
    /*display: flex;*/
    color: azure;
    /* width: 15%;
    */
  }
  .social-links ul li {
    margin-right: 0px;
    float: right;
    list-style-type: none;
  }
  .social-links ul li img {
    width: 30px;
    cursor: pointer;
  }
  .img-z {
    z-index: 9;
  }

  .about-gif-part {
    text-align: -webkit-center;
    margin-top: 15px;
  }
  .about-gif-part img {
    margin-top: 0px;
    width: 100px;
  }
  .about-trasition-1 {
    /* transform: translate(0%, 10%); */
    padding: 7px 150px 0px 150px;
    margin-bottom: 25%;
  }
  .token-read1 {
    /* transform: translate(0%, 20%); */
    padding: 0px 50px 0px 50px;
  }

  .token-read1 p {
    font-size: 14px;
  }

  .wel-trasition-1 {
    transform: translate(0%, -35%);
    padding: 0px 50px 0px 50px;
  }

  .trasition-2 img {
    width: 100%;
  }

  .supply-part {
    /* align-items: baseline; */
    left: 0;
    right: 0;
    /* display: flex; */
    padding: 0 0px;
  }
  .supply-ocb {
    text-align: -webkit-center;
    margin-top: 10px;
  }

  .supply-token {
    padding: 20px;
  }
  .supply-token p {
    margin-bottom: 1px;
    margin-left: 1px;
    text-align: left;
    font-size: 22px;
  }
  .trasition-1 img {
    width: 80px;
    margin-bottom: 5px;
  }

  .rope-img {
    transform: translateX(0%);
    bottom: 0%;
    width: 100%;
    left: 0;
    position: absolute;
    display: flex;
    padding: 10px 0px;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  /* .rope-img img {
    width: 120px;
  } */
  .icon-1 {
    text-align: -webkit-right;
    /* position: absolute; */
    margin-left: 0px;
    width: 100%;
    margin-top: -40px !important;
  }
}

.tap-points {
  position: absolute;
  font-size: 20px;
  font-weight: bold;
  color: #ff4500;
  animation: fadeUp 2s forwards;
  pointer-events: none;
  font-family: mySirstFont;
  z-index: 10;
}

.container {
  position: relative;
  display: inline-block; /* Ensure the container fits the size of the image */
}

.gif-size {
  display: block;
  width: 100%; /* Adjust as needed */
  height: auto; /* Maintain aspect ratio */
}

@keyframes fadeUp {
  0% {
    opacity: 1;
    transform: translateY(0) scale(0.8);
  }
  100% {
    opacity: 0;
    transform: translateY(-50px) scale(1);
  }
}

.tap-point {
  position: absolute;
  font-family: mySirstFont;
  transform: translate(-50%, -50%); /* Center the point */
  border-radius: 50%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  animation: fadeUp 3s ease-out forwards; /* Apply the animation */
  background: linear-gradient(
    180deg,
    #fd8d23 -1.34%,
    #fae61c 10.31%,
    #eef73b 20.95%,
    #4cf038 42.23%,
    #33c6f6 65.04%,
    #559cfb 73.14%,
    #7475ff 80.74%,
    #d457ff 100%
  );

  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}
